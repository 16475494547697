import axios from "axios";

const errorCodes = {
    badRequest: "badRequest",
    unauthorized: "unauthorized",
    forbidden: "forbidden",
    notFound: "notFound",
    serverError: "serverError",
    unexpected: "unexpected",
    invalidCredentials: "invalidCredentials",
};

class API {
    constructor(collection) {
        this.collectionUrl = `${process.env.REACT_APP_API_BASE_URL}/${collection}`;
    }

    _handleError(error) {
        if (error.response?.status === 400)
            return Promise.reject({
                code: errorCodes.badRequest,
                message: error.response.data,
            });
        if (error.response?.status === 401)
            return Promise.reject(errorCodes.unauthorized);
        if (error.response?.status === 403)
            return Promise.reject(errorCodes.forbidden);
        if (error.response?.status === 404)
            return Promise.reject(errorCodes.notFound);
        if (500 <= error.response?.status <= 599)
            return Promise.reject(errorCodes.serverError);
        return Promise.reject(errorCodes.unexpected);
    }

    async getMulti(
        ordering = null,
        search = null,
        limit = null,
        offset = null,
        extra = {}
    ) {
        const params = { limit, offset, ...extra };
        if (ordering) params.order_by = ordering;
        if (search && search.length > 0) params.search = search;
        try {
            const response = await axios.get(`${this.collectionUrl}/`, {
                params,
            });
            return Promise.resolve(response.data);
        } catch (error) {
            return this._handleError(error);
        }
    }

    async getOne(id) {
        try {
            const response = await axios.get(`${this.collectionUrl}/${id}/`);
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async create(data) {
        try {
            const response = await axios.post(`${this.collectionUrl}/`, data);
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async update(id, data, partial = false) {
        try {
            let response = null;
            if (partial)
                response = await axios.patch(
                    `${this.collectionUrl}/${id}/`,
                    data
                );
            else
                response = await axios.put(
                    `${this.collectionUrl}/${id}/`,
                    data
                );
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async delete(id) {
        try {
            const response = await axios.delete(`${this.collectionUrl}/${id}/`);
            return response;
        } catch (e) {
            return this._handleError(e);
        }
    }
}

class UserAPI extends API {
    async getToken(email, password) {
        try {
            const response = await axios.post(`${this.collectionUrl}/token/`, {
                email,
                password,
            });
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials);
        }
    }

    async changePassword(newPass) {
        try {
            const response = await axios.post(
                `${this.collectionUrl}/me/change-password/`,
                { new: newPass }
            );
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async getMe() {
        try {
            const response = await axios.get(`${this.collectionUrl}/me/`);
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }
}


class PasswordAPI extends API {

    async sendEmail(email) {
        try {
            const response = await axios.post(`${this.collectionUrl}/`, {
                email: email,
            });
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async changePassword(password, uuid, token) {
        try {
            const response = await axios.post(
                `${this.collectionUrl}/${uuid}/${token}/`,
                { password: password }
            );
        } catch (e) {
            return this._handleError(e);
        }
    }
}
class CompanyAPI extends API {
    async checkCompany(company) {
        try {
            const response = await axios.get(
                `${this.collectionUrl}/check_name/`,
                {
                    params: { name: company },
                }
            );
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    async sendEmail(email, company) {
        try {
            const response = await axios.post(`${this.collectionUrl}/invite_user/`, {
                email: email,
                companyId: company,
            });
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }
}

class ImprovementAPI extends API {
    async getRecommendations(company) {
        try {
            const response = await axios.post(
                `${this.collectionUrl}/recommendations/`,
                {
                    company_id: company,
                }
            );
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(e);
        }
    }
}

class UserResponsesAPI extends API {
    async getHistory(company) {
        try {
            const response = await axios.get(
                `${this.collectionUrl}/history/`,
                {
                    params: { company: company, },
                }
            );
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(e);
        }
    }
}

const api = {
    users: new UserAPI("users"),
    companies: new CompanyAPI("companies"),
    dimensions: new API("dimensions"),
    improvementArticles: new ImprovementAPI("improvement-articles"),
    learnArticles: new API("learning-articles"),
    broadcastArticles: new API("diffusion-articles"),
    consultations: new API("consultations"),
    questions: new API("questions"),
    userResponses: new UserResponsesAPI("user-responses"),
    rounds: new API("rounds"),
    sectors: new API("sectors"),
    categories: new API("categories"),
    tags: new API("tags"),
    reset_password: new PasswordAPI("reset-password"),
};

export default api;
export { errorCodes, API, UserAPI };
