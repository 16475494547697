import { ArrowUpRightIcon, Bars4Icon, CheckCircleIcon } from "@heroicons/react/24/outline"
import { useNavigate } from "react-router-dom"
import LearnArticle from "../assets/LearnArticle.png"
import TestImage from "../assets/Test.jpg"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function LearnCard({ title, category, subtitle, idArticle, tags, image }) {

    const navigate = useNavigate()

    return (
        <div className='rounded-[10px] overflow-hidden bg-white flex flex-col gap-3 relative shadow-md'>
            <div className="w-full relative">
                <div className="bg-[#F27F50] absolute w-fit text-white text-sm font-medium top-3 left-4 rounded-md py-2 px-3"><p>{category}</p></div>
                <img src={image ? image : TestImage} className="w-full h-56 overflow-hidden object-cover object-center" />
            </div>
            <div className="flex flex-col h-full justify-between px-6 py-2">
                <div className="flex flex-col">
                    <p className="text-[#F27F50] font-medium text-xs">{tags.map((element) => element.name).join(', ')}</p>
                    <p className="text-[#111827] font-semibold my-2 text-xl">{title}</p>
                    <p className="text-[#6B7280] text-lg">{subtitle}</p>
                </div>
                <button onClick={() => navigate(`/learn/${idArticle}`)} className="bg-[#F27F50] w-full text-white font-medium mt-10 mb-4 rounded-md py-2.5">
                    Leer más
                </button>
            </div>

        </div>
    )

}