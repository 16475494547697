import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import { useAuth } from "./context/authContext";
import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import CompanyProfile from "./pages/CompanyProfile";
import DiagnosisPage from "./pages/DiagnosisPage";
import QuestionsPage from "./pages/QuestionsPage";
import ProgressPage from "./pages/ProgressPage";
import ImprovePage from "./pages/ImprovePage";
import ImprovementArticlePage from "./pages/ImprovementArticlePage";
import UserProfile from "./pages/UserProfile";
import LearnPage from "./pages/LearnPage";
import LearningArticlePage from "./pages/LearningArticlePage";
import BroadcastPage from "./pages/BroadcastPage";
import BroadcastArticlePage from "./pages/BroadcastArticlePage";
import ConsultationsPage from "./pages/ConsultationsPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import RecordPage from "./pages/RecordPage";
import TermsPage from "./pages/TermsPage";

function App() {
  const auth = useAuth();

  const authenticatedRoutes = (
    <Routes>
      <Route path="/company-profile" element={<CompanyProfile />} />
      <Route path="/user-profile" element={<UserProfile />} />
      <Route path="/diagnosis" element={<DiagnosisPage />} />
      <Route path="/diagnosis/:idDimension" element={<QuestionsPage />} />
      <Route path="/diagnosis/:idDimension/:idSubdimension" element={<QuestionsPage />} />
      <Route path="/progress" element={<ProgressPage />} />
      <Route path="/history" element={<RecordPage />} />
      <Route path="/improvements" element={<ImprovePage />} />
      <Route path="/improvements/:idArticle" element={<ImprovementArticlePage />} />
      <Route path="/learn" element={<LearnPage />} />
      <Route path="/learn/:idArticle" element={<LearningArticlePage />} />
      <Route path="/broadcast" element={<BroadcastPage />} />
      <Route path="/broadcast/:idArticle" element={<BroadcastArticlePage />} />
      <Route path="/consultations" element={<ConsultationsPage />} />
      <Route path="/terms-and-conditions" element={<TermsPage />} />
      <Route path="*" element={<Navigate to="/diagnosis" />} />
    </Routes>
  );

  return (
    <Router>
      {auth.isAuthenticated ? (
        authenticatedRoutes
      ) : (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route
            path="/reset-password/:uuid/:token"
            element={<ResetPasswordPage />}
          />
          <Route path="/terms-and-conditions" element={<TermsPage />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
