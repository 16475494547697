import { useState } from "react";
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PasswordInput({ name, label, labelClassName, placeholder, value, containerClassName, handleChange, error }) {

    const [showPassword, setShowPassword] = useState(false)

    return (
        <div>
            <label htmlFor={name} className={classNames(labelClassName ? labelClassName : "block text-sm font-medium leading-6 text-gray-900")}>
                {label}
            </label>
            <div className="flex w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400  sm:text-sm">
                <input
                    name={name}
                    id={name}
                    value={value}
                    type={showPassword ? 'text' : 'password'}
                    onChange={(event) => handleChange(name, event.target.value)}
                    className="appearance-none w-full rounded-l-md flex flex-1 px-3 py-2 outline-none border-none"
                />
                <button
                    onClick={() => setShowPassword(!showPassword)}
                    type="button"
                    className="px-3 w-fit bg-white overflow-hidden rounded-r-md text-gray-400 hover:text-gray-700"
                >
                    {showPassword ?
                        <EyeSlashIcon className="h-5 w-5" aria-hidden="true" /> :
                        <EyeIcon className="h-5 w-5" aria-hidden="true" />
                    }
                </button>
            </div>
        </div>
    )
}