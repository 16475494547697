import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import ECULogo from "../assets/ECULogo.svg";
import PYFlag from "../assets/PYFlag.svg";
import { useAuth } from "../context/authContext";
import LoginBackground from "../assets/LoginBackground.jpg";
// import Modal from '../components/Modal';
import api from "../api";
import PasswordInput from "../components/PasswordInput";
import TextInput from "../components/TextInput";
import InlineCheckbox from "../components/InlineCheckbox";

export default function RegistrationPage() {
  const [profile, setProfile] = useState(undefined);
  const [company, setCompany] = useState(undefined);
  const [error_message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  const handleChange = (field, value) =>
    setProfile((prev) => ({ ...prev, [field]: value }));
  const handleCompany = (field, value) => setCompany(value);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setLoading(true);
    const result = await api.companies.checkCompany(company);
    if (!result.exists) {
      api.users
        .create(profile)
        .then((response) => {
          auth
            .signIn(profile.email, profile.password)
            .then((responseB) => {
              setLoading(false);
              console.log(company);
              navigate("/company-profile", { state: company });
            })
            .catch((e) => {
              setLoading(false);
              setErrorMessage(e.message);
            });
        })
        .catch((e) => {
          setLoading(false);
          setErrorMessage(e.message);
        });
    } else {
      setLoading(false);
      setErrorMessage({ company: "Esta empresa ya existe." });
    }
  };

  return (
    <div className="min-h-screen h-full flex justify-center select-none ">
      <div className="w-2/5 items-center my-auto px-32 pt-[7%]">
        <div className="sm:w-full sm:max-w-md">
          <div className="absolute left-0 top-[1%] bg-[#F8F7FA] flex items-center w-[200px] gap-[10px] pr-[40px] py-[20px]">
            <img className="ml-5" src={PYFlag} alt="Paraguay" />
            <p className="text-[12px] text-[#111827] font-extrabold uppercase">
              Paraguay
            </p>
          </div>
          <img className="h-auto w-24" src={ECULogo} alt="ECU" />
          <h2 className="mt-4 text-left text-[30px] font-extrabold leading-9 text-[#111827]">
            Creá una cuenta
          </h2>
          <div className="mt-2">
            <p className="inline-flex">O </p>
            <p
              onClick={() => navigate("/login")}
              className="ml-1 inline-flex text-primary cursor-pointer"
            >
              iniciá sesión acá
            </p>
          </div>
        </div>
        <form
          className="sm:max-w-md space-y-6 mt-2"
          onSubmit={(event) => handleSubmit(event)}
        >
          <TextInput
            error={error_message.firstName}
            handleChange={handleChange}
            name="firstName"
            label="Nombre"
          />
          <TextInput
            error={error_message.lastName}
            handleChange={handleChange}
            name="lastName"
            label="Apellido"
          />
          <TextInput
            error={error_message.company}
            handleChange={handleCompany}
            name="company"
            label="Empresa / Organización"
          />
          <TextInput
            error={error_message.email}
            handleChange={handleChange}
            name="email"
            label="Correo Electrónico"
          />
          <PasswordInput
            error={error_message.password}
            handleChange={handleChange}
            name="password"
            label="Contraseña"
          />
          <InlineCheckbox
            error={error_message.terms}
            handleChange={handleChange}
            name="terms"
            label={
              <span>
                Acepto los{" "}
                <Link
                  target="_BLANK"
                  rel="noopener noreferrer"
                  className="underline cursor-pointer"
                  to="/terms-and-conditions"
                >
                  términos y condiciones
                </Link>
              </span>
            }
          />
          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary    "
            >
              {loading ? (
                <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin"></div>
              ) : (
                "Completar registro"
              )}
            </button>
          </div>
        </form>
      </div>
      <img className="w-3/5 max-h-screen object-cover" src={LoginBackground} />
    </div>
  );
}
