function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PercentageInput({ name, label, labelClassName, placeholder, description, value, disabled, containerClassName, handleChange, error }) {
    return (
        <div className="mt-1 mx-auto w-fit grid grid-cols-2 align-middle gap-3">
            <input
                type="text"
                onChange={(event) => { if (!(parseInt(event.target.value) > 100 || parseInt(event.target.value) < 0)) handleChange(name, event.target.value) }}
                name={name}
                min={0}
                max={100}
                id={name}
                disabled={disabled}
                value={value !== undefined ? value : ''}
                onKeyDown={(event) => { if (/[0-9]/.test(event.key) || event.key === 'Backspace' || event.key === 'Tab') { } else { event.preventDefault(); } }}
                className="placeholder:text-[#757575] bg-gray-50 text-center text-primary font-medium placeholder:font-semibold placeholder:text-xs block w-32 h-fit shadow-sm border-0 border-b-2 border-primary focus-visible:border-primary focus-visible:outline-none focus-visible:ring-0 sm:text-sm"
                placeholder={placeholder}
            />
            <p className="text-primary font-semibold mt-3 text-xl text-left">% {description}</p>
        </div>
    )

}