import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import api from "../api";
import Sidebar from "../components/Sidebar";
import TextInput from "../components/TextInput";
import SelectInput from "../components/SelectInput";
import FileInput from "../components/FileInput";
import CustomDropzone from "../components/CustomDropzone";

const sizes = [
    { label: '1 a 10 empleados/as', value: 1 },
    { label: '11 a 50 empleados/as', value: 2 },
    { label: '51 a 200 empleados/as', value: 3 },
    { label: '201 a 1000 empleados/as', value: 4 },
]

export default function CompanyProfile() {

    const [company, setCompany] = useState(undefined)
    const [user, setUser] = useState(undefined)
    const [error_message, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(true)
    const [showPassword, setShowPassword] = useState(false)
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [sectors, setSectors] = useState(undefined)
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (state)
            handleChange("name", state)
    }, [state])

    useEffect(() => {
        api.sectors.getMulti().then((resultSectors) => {
            setSectors(resultSectors)
            api.users.getMe().then((response) => {
                setUser(response)
                if (response.company)
                    api.companies.getOne(response.company).then((result) => {
                        setCompany(result)
                        setLoading(false)
                    }).catch((error) => console.log(error))
                else
                    setLoading(false)
            })
        })

    }, [])

    const handleChange = (field, value) => setCompany((prev) => ({ ...prev, [field]: value }));

    const handleSubmit = async (event) => {
        setErrorMessage('')
        setLoading(true)
        let formData = new FormData();
        for (var key in company) {
            if (company[key]) {
                formData.append(key, company[key]);
            }
        }
        api.companies.create(formData)
            .then((response) => {
                console.log(response)
                api.users.update(user.id, { ...user, company: response.id }, true).then(() => {
                    setLoading(false)
                    navigate('/diagnosis')
                })
            })
            .catch((e) => {
                setLoading(false)
                setErrorMessage(e.message)
            });
    }

    return (
        <div className="min-h-screen h-full w-full flex select-none bg-gray-50">
            <Sidebar userProfile={true} currentScreen={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            {loading ?
                <div className="w-16 h-16 mx-auto my-auto border-b-2 border-gray-500 rounded-full animate-spin" />
                :
                <div className="md:pl-80 h-screen my-6 w-full">
                    <p className="text-[30px] font-extrabold">Información de la empresa</p>
                    <div className="grid grid-cols-2 gap-x-6 gap-y-4 w-2/3 mt-2">
                        <TextInput error={error_message.name} value={company?.name} handleChange={handleChange} name='name' label='Nombre de la empresa' />
                        <TextInput error={error_message.contactName} value={company?.contactName} handleChange={handleChange} name='contactName' label='Nombre del contacto responsable de la empresa' />
                        <TextInput error={error_message.contactEmail} value={company?.contactEmail} handleChange={handleChange} name='contactEmail' label='Contacto del responsable de la empresa' />
                        <TextInput error={error_message.contactPosition} value={company?.contactPosition} handleChange={handleChange} name='contactPosition' label='Cargo del contacto responsable de la empresa' />
                        <SelectInput error={error_message.sector} value={company?.sector} placeholderText='Seleccione una opción' placeholder={true} handleChange={handleChange} name='sector' label='Sector' options={sectors.map((element) => (<option key={element.name} label={element.name} value={element.id}>{element.name}</option>))} />
                        <SelectInput error={error_message.size} value={company?.size} placeholderText='Seleccione una opción' placeholder={true} handleChange={handleChange} name='size' label='Tamaño de la empresa' options={sizes.map((element) => (<option key={element.label} label={element.label} value={element.value}>{element.label}</option>))} />
                    </div>
                    <p className="text-[30px] font-extrabold mt-6">Verificación de la empresa</p>
                    <p className="mt-4">Adjuntar documento de validación</p>
                    <CustomDropzone error={error_message.validationDocument} handleChange={handleChange} name='validationDocument' />
                    <div className="flex w-2/3 gap-6 mt-6">
                        <button className="flex w-full justify-center border border-[#DBDBDB] gap-2 py-2 px-4 rounded-md shadow-sm text-sm text-[#374151] font-medium bg-white">
                            Cancelar
                        </button>
                        <button onClick={(event) => handleSubmit(event)} className="flex w-full justify-center border border-primary gap-2 py-2 px-4 rounded-md shadow-sm text-sm text-white font-medium bg-primary">
                            Actualizar perfil
                        </button>
                    </div>
                </div>}
        </div >
    );
}
