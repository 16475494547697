import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ECULogo from "../assets/ECULogo.svg";
import PYFlag from "../assets/PYFlag.svg";
import LoginBackground from "../assets/LoginBackground.jpg";
import { useAuth } from "../context/authContext";
import Modal from "../components/Modal";
import { Dialog } from "@headlessui/react";
import api from "../api";
import TextInput from "../components/TextInput";
import PasswordInput from "../components/PasswordInput";

export default function LoginPage() {
  const [login, setLogin] = useState(undefined);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [error_message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  const handleChange = (field, value) =>
    setLogin((prev) => ({ ...prev, [field]: value }));

  const recoverPassword = async () => {
    await api.reset_password
      .sendEmail(login.email)
      .then((response) => {
        console.log(response);
        setOpenForgotPassword(false);
      })
      .catch((error) => console.log(error));
  };

  const forgotPassword = (
    <>
      <div>
        <div className="text-center">
          <Dialog.Title
            as="h3"
            className="text-lg leading-6 font-semibold text-gray-900"
          >
            Recuperar Contraseña
          </Dialog.Title>
          <div className="mt-2 w-full">
            <label
              htmlFor="password"
              className="text-sm font-medium text-gray-700"
            >
              Correo Electrónico
            </label>
            <div className="relative w-2/3 rounded-md mx-auto shadow-sm">
              <input
                type="email"
                name="email"
                onChange={(event) => handleChange("email", event.target.value)}
                id="email"
                className="block mt-1 w-full h-8 pr-10 sm:text-sm border pl-1 border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
          onClick={() => recoverPassword()}
        >
          Aceptar
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={() => setOpenForgotPassword(false)}
        >
          Cancelar
        </button>
      </div>
    </>
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setLoading(true);
    auth
      .signIn(login.email, login.password)
      .then((response) => {
        console.log(response);
        setLoading(false);
        navigate("/diagnosis");
      })
      .catch((e) => {
        setLoading(false);
        setErrorMessage(e.detail);
      });
  };

  return (
    <div className="min-h-screen h-full flex justify-center select-none ">
      <div className="w-2/5 items-center my-auto px-24 pt-[5%]">
        <div className="absolute left-0 top-[2%] bg-[#F8F7FA] flex items-center w-[200px] gap-[10px] pr-[40px] py-[20px]">
          <img className="ml-5" src={PYFlag} alt="Paraguay" />
          <p className="text-[12px] text-[#111827] font-extrabold uppercase">
            Paraguay
          </p>
        </div>
        <Modal
          size="sm:max-w-lg w-full"
          content={forgotPassword}
          open={openForgotPassword}
          setOpen={setOpenForgotPassword}
        />
        <div className="mt-4 sm:w-full sm:max-w-md">
          <img className="h-auto w-24" src={ECULogo} alt="ECU" />
          <h2 className="mt-4 text-[30px] leading-9 text-[#111827] font-extrabold ">
            Ingresá a tu cuenta
          </h2>
          <div className="mt-2">
            <p className="inline-flex">O </p>
            <p
              onClick={() => navigate("/registration")}
              className="ml-1 inline-flex text-primary cursor-pointer"
            >
              regístrate acá
            </p>
          </div>
        </div>
        <form
          className="sm:max-w-md space-y-6 mt-6"
          onSubmit={(event) => handleSubmit(event)}
        >
          <TextInput
            handleChange={handleChange}
            name="email"
            label="Correo Electrónico"
          />
          <PasswordInput
            handleChange={handleChange}
            name="password"
            label="Contraseña"
          />
          {error_message === "" ? null : (
            <div
              className="bg-red-100 mt-4 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline">Credenciales inválidas.</span>
            </div>
          )}
          <div className="flex justify-start">
            <div className="text-sm">
              <button
                type="button"
                onClick={() => setOpenForgotPassword(true)}
                className="font-medium text-primary"
              >
                ¿Olvidaste tu contraseña?
              </button>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary    "
            >
              {loading ? (
                <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin"></div>
              ) : (
                "Iniciar sesión"
              )}
            </button>
          </div>
        </form>
      </div>
      <img className="w-3/5 max-h-screen object-cover" src={LoginBackground} />
    </div>
  );
}
