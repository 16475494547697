import React, { useState, useContext, useEffect } from "react";
import { Fragment } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react'
import Modal from './Modal'
import ECULogo from '../assets/ECULogo.svg';
import UNICEFLogo from '../assets/UNICEFLogo.svg';
import TextInput from '../components/TextInput';
import { LoudspeakerIcon, BoardIcon, StatsIcon } from "../assets/Icons";
import { EyeIcon, EyeSlashIcon, ExclamationTriangleIcon, XMarkIcon, ArrowPathIcon, UserCircleIcon, UserGroupIcon, ChartBarIcon, BuildingOffice2Icon, UsersIcon, ClipboardIcon, HomeIcon, PencilSquareIcon, SpeakerWaveIcon, InformationCircleIcon, QuestionMarkCircleIcon, ShareIcon, ArrowRightStartOnRectangleIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../context/authContext';
import api from "../api";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Sidebar(props) {

    const [openLogout, setOpenLogout] = useState(false)
    const [visible, setVisible] = useState(false)
    const [user, setUser] = useState(undefined)
    const [company, setCompany] = useState(undefined)
    const [openChangePassword, setOpenChangePassword] = useState(false)
    const [openInvite, setOpenInvite] = useState(false)
    const [openAbout, setOpenAbout] = useState(false)
    const [success, setSuccess] = useState("")
    const [inviteEmail, setInviteEmail] = useState(undefined)
    const [loadingChangePassword, setLoadingChangePassword] = useState(false)
    const [loadingInvite, setLoadingInvite] = useState(false)
    const [changePasswordError, setChangePasswordError] = useState('')
    const [inviteError, setInviteError] = useState('')
    const [password, setPassword] = useState("")
    const [fullName, setFullName] = useState(JSON.parse(localStorage.getItem('ECUuserData'))?.firstName + ' ' + JSON.parse(localStorage.getItem('ECUuserData'))?.lastName)
    const [userRole, setUserRole] = useState(JSON.parse(localStorage.getItem('ECUuserData'))?.role)
    const auth = useAuth();
    const navigate = useNavigate();
    const [navigation, setNavigation] = useState([
        { name: 'Progreso', href: '/progress', icon: HomeIcon, },
        { name: 'Diagnosticar', href: '/diagnosis', icon: PencilSquareIcon, },
        { name: 'Mejorar', href: '/improvements', icon: StatsIcon, },
        { name: 'Aprender', href: '/learn', icon: BoardIcon, },
        { name: 'Difundir', href: '/broadcast', icon: LoudspeakerIcon, },
    ])
    const [info, setInfo] = useState([
        { name: 'Sobre ECU', href: '#', icon: InformationCircleIcon, },
        { name: 'Consultas', href: '#', icon: QuestionMarkCircleIcon, },
    ])
    const handleInvite = (field, value) => {
        setInviteEmail(value);
    }

    useEffect(() => {
        api.users.getMe().then((response) => {
            if (response.company)
                api.companies.getOne(response.company).then((result) => {
                    setCompany(result)
                }).catch((error) => console.log(error))
        }).catch((error) => console.log(error))
    }, [])

    const submitChangePassword = async () => {
        setChangePasswordError('')
        setLoadingChangePassword(true)
        await api.users.changePassword(password)
            .then((response) => {
                setLoadingChangePassword(false)
                console.log(response)
                setOpenChangePassword(false)
            })
            .catch((error) => {
                setLoadingChangePassword(false)
                let msg = ''
                if (error.message) {
                    error.message.new.forEach(m => {
                        msg = msg + m + `\n`
                    })
                } else {
                    msg = 'Hubo un problema al cambiar la contraseña'
                }
                setChangePasswordError(msg)
                console.log(error)
            })
    }

    const submitInvite = async () => {
        setLoadingInvite(true)
        setInviteError('')
        await api.companies.sendEmail(inviteEmail, company.id).then((response) => {
            setSuccess(true)
            setLoadingInvite(false)
        }).catch((error) => {
            setInviteError(error)
            setLoadingInvite(false)
        })
    }

    const logout = (
        <>
            < div >
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                    <ExclamationTriangleIcon className="h-6 w-6 text-yellow-800" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Cerrar sesión
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            ¿Estás seguro de que quieres salir?
                        </p>
                    </div>
                </div>
            </div >
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
                    onClick={async () => {
                        await auth.signOut();
                        navigate('/login');
                    }}
                >
                    Cerrar Sesión
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenLogout(false)}
                >
                    Cancelar
                </button>
            </div>
        </>
    )

    const changepassword = (
        <>
            < div >
                <div className="text-center">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900">
                        Cambiar contraseña
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                        <label htmlFor="password" className="text-sm font-medium text-gray-700">
                            Nueva contraseña
                        </label>
                        <div className="relative w-2/3 rounded-md mx-auto shadow-sm">
                            <input
                                type={visible ? "text" : "password"}
                                name="password"
                                onChange={(event) => setPassword(event.target.value)}
                                id="password"
                                className="block mt-1 w-full pl-1 h-8 pr-10 sm:text-sm border-gray-300 rounded-md"
                            />
                            <button onClick={() => setVisible(!visible)} className="absolute inset-y-0 right-0 pr-3 flex items-center">
                                {visible ?
                                    <EyeSlashIcon className="h-5 w-5 text-gray-400 hover:text-blue-400" aria-hidden="true" /> :
                                    <EyeIcon className="h-5 w-5 text-gray-400 hover:text-blue-400" aria-hidden="true" />
                                }
                            </button>
                        </div>
                        {!!changePasswordError && <div className="mt-2 mb-4 text-sm text-red-600">
                            {changePasswordError}
                        </div>}
                    </div>
                </div>
            </div >
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
                    onClick={() =>
                        submitChangePassword()
                    }
                >
                    {loadingChangePassword ?
                        <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin"></div> :
                        'Guardar'
                    }
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenChangePassword(false)}
                >
                    Cancelar
                </button>
            </div>
        </>
    )

    const inviteTeammate = (
        <>
            < div className="flex flex-col gap-12" >
                <XMarkIcon onClick={() => setOpenInvite(false)} className="w-6 h-6 self-end cursor-pointer" />
                <div className="text-center">
                    <UserGroupIcon className="w-12 h-12 mx-auto text-[#9CA3AF]" />
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium mt-4 text-primary">
                        Añadí a un miembro de tu equipo
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                        <label htmlFor="password" className="text-sm font-medium text-[#6B7280]">
                            Ingresá el correo del miembro de tu equipo para que forme parte de
                        </label>
                        <label className="text-sm font-semibold text-[#6B7280] ml-1">
                            {company?.name}
                        </label>
                        <TextInput containerClassName='mt-4 mx-auto' handleChange={handleInvite} placeholder="Ingresá su correo" name='email' />
                    </div>
                </div>
            </div >
            <div className="mt-5">
                <button
                    type="button"
                    onClick={() => submitInvite()}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
                >
                    {loadingInvite ?
                        <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin"></div> :
                        'Enviar invitación'
                    }
                </button>
                {success && <div className="border bg-[#ECFDF5] rounded-lg p-1.5 text-left text-sm w-full mt-4">
                    <p className="text-[#065F46] font-medium">¡{inviteEmail} fue invitado!</p>
                    <p className="text-[#047857] mt-2">Le enviamos un correo. Una vez que acepte la invitación ya va a poder colaborar en ECU</p>
                </div>}
            </div>
        </>
    )

    const aboutECU = (
        <>
            < div className="flex flex-col" >
                <XMarkIcon onClick={() => setOpenAbout(false)} className="w-6 h-6 self-end cursor-pointer" />
                <div className="text-left">
                    <Dialog.Title as="h3" className="text-2xl leading-6 font-bold mt-4 text-[#2095DF]">
                        Conocé qué acciones puede llevar adelante tu empresa
                    </Dialog.Title>
                    <div className="mt-10 w-full">
                        <label htmlFor="password" className="text-xl font-normal text-[#6B7280]">ECU es una herramienta de mejora continua que le ayuda a tu empresa a mejorar las políticas de conciliación de la vida laboral y familiar y garantizar los derechos de niñas y niños al cuidado.</label>
                        <div className="bg-[#EFF6FF] rounded-lg flex gap-3 mt-8 p-5">
                            <InformationCircleIcon className="w-6 h-6 text-[#60A5FA]" />
                            <p className="font-medium text-sm text-[#1E40AF]">Ante cualquier duda o sugerencia escribinos a ecu.paraguay@unicef.org</p>
                        </div>
                    </div>
                    <div className="mt-8 text-[#1D4ED8] font-medium flex gap-3 justify-center">
                        <p className="my-auto">Una iniciativa de</p>
                        <img className="h-full w-auto" src={UNICEFLogo} alt='UNICEF' />
                    </div>
                </div>
            </div >
        </>
    )

    return (
        <div>
            <Modal size='sm:max-w-lg w-full' content={changepassword} open={openChangePassword} setOpen={setOpenChangePassword} />
            <Modal size='sm:max-w-lg w-full' content={logout} open={openLogout} setOpen={setOpenLogout} />
            <Modal size='sm:max-w-md w-full' content={inviteTeammate} open={openInvite} setOpen={setOpenInvite} />
            <Modal size='sm:max-w-lg w-full' content={aboutECU} open={openAbout} setOpen={setOpenAbout} />
            <Transition.Root show={props.sidebarOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-50 md:hidden" onClose={props.setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 bg-white">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => props.setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-shrink flex items-center h-15 pr-4 pl-8">
                                <img
                                    className="h-full w-auto"
                                    src={ECULogo}
                                    alt="Workflow"
                                />
                            </div>
                            <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                <nav className="px-2 space-y-1">
                                    {navigation.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            className={classNames(
                                                props.currentScreen === item.name
                                                    ? 'bg-gray-100 text-gray-900'
                                                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                            )}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                    'mr-4 flex-shrink-0 h-6 w-6'
                                                )}
                                                color={props.currentScreen === item.name ? 'white' : '#7292FF'}
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </Link>
                                    ))}
                                </nav>
                            </div>
                            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                                <div className="flex-col space-y-3 ml-3 items-center">
                                    <p className="text-sm font-medium text-gray-700">{localStorage.getItem('fullName')}</p>
                                    <button onClick={() => { setOpenChangePassword(true); props.setSidebarOpen(false) }} className="flex-shrink-0 w-full group block">
                                        <p className="text-xs font-medium text-primary group-hover:text-primary">Cambiar contraseña</p>
                                    </button>
                                    <button onClick={() => { setOpenLogout(true); props.setSidebarOpen(false) }} className="flex-shrink-0 w-full group block -ml-5">
                                        <p className="text-xs font-medium text-primary group-hover:text-primary">Cerrar sesión</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 z-10">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-white overflow-y-auto">
                    <div className="flex items-center flex-shrink-0 px-8">
                        <img
                            className="h-15 w-auto"
                            src={ECULogo}
                            alt="Workflow"
                        />
                    </div>
                    <div className="mt-5 flex-grow flex flex-col">
                        <nav className="flex-1 px-2 pb-4 space-y-3">
                            {navigation.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.href}
                                    className={classNames(
                                        props.currentScreen === item.name ? 'bg-primary text-white' : 'text-[#7292FF] hover:bg-gray-50 hover:text-[#7292FF]',
                                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                    )}
                                >
                                    <item.icon
                                        className={classNames(
                                            props.currentScreen === item.name ? 'text-white' : 'text-[#7292FF] group-hover:text-[#7292FF]',
                                            'mr-3 flex-shrink-0 h-6 w-6'
                                        )}
                                        aria-hidden="true"
                                    />
                                    {item.name}
                                </Link>
                            ))}
                            <div className="border border-[#D9D9D9] w-full" />
                            <div
                                key='Sobre ECU'
                                onClick={() => setOpenAbout(true)}
                                className={classNames('cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md')}
                            >
                                <InformationCircleIcon
                                    className={classNames('mr-3 flex-shrink-0 h-6 w-6')}
                                    aria-hidden="true"
                                />
                                Sobre ECU
                            </div>
                            <Link
                                key='Consultas'
                                to='/consultations'
                                className={classNames('text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md')}
                            >
                                <QuestionMarkCircleIcon
                                    className={classNames('mr-3 flex-shrink-0 h-6 w-6')}
                                    aria-hidden="true"
                                />
                                Consultas
                            </Link>
                        </nav>
                    </div>
                    <div className="flex-shrink-0 flex p-4">
                        <div className="flex-col space-y-3 items-center">
                            <button onClick={props.userProfile ? () => navigate('/user-profile') : () => navigate('/company-profile')} className=" flex flex-shrink-0 w-full">
                                <div className="bg-[#2095DF40] border-[#2095DF40] rounded-[20px] block p-2">
                                    <BuildingOffice2Icon className='text-[#2095DF] flex-shrink-0 h-6 w-6' aria-hidden="true" />
                                </div>
                                <div className="flex flex-col gap-1.5 ml-3 justify-start text-left">
                                    {props.userProfile ?
                                        <>
                                            <p className="text-xs font-medium text-[#6B7280] my-auto">Ver mi perfil</p>
                                        </>
                                        :
                                        <>
                                            <p className="text-sm font-medium text-[#374151] truncate">{company?.name}</p>
                                            <p className="text-xs font-medium text-[#6B7280] ">Ajustes</p>
                                        </>
                                    }
                                </div>
                            </button>
                            <button onClick={() => setOpenInvite(true)} className="flex flex-shrink-0 w-full">
                                <div className="bg-[#4DD6B636] border-[#4DD6B636] rounded-[20px] block p-2">
                                    <ShareIcon className='text-primary flex-shrink-0 h-6 w-6' aria-hidden="true" />
                                </div>
                                <p className="text-xs font-medium text-primary ml-3 my-auto">Compartir con un compañero</p>
                            </button>
                            <div className="border border-[#D9D9D9] w-full" />
                            <button onClick={() => setOpenLogout(true)} className="flex-shrink-0 w-fit block">
                                <div className="flex gap-3 ml-3">
                                    <ArrowRightStartOnRectangleIcon className='text-[#F75452] flex-shrink-0 h-6 w-6' aria-hidden="true" />
                                    <p className="text-xs my-auto font-medium text-[#F75452]">Cerrar sesión</p>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
