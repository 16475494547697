import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import api from "../api";
import Sidebar from "../components/Sidebar";
import TextInput from "../components/TextInput";
import PasswordInput from "../components/PasswordInput";

export default function UserProfile() {

    const [user, setUser] = useState(undefined)
    const [error_message, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(true)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [oldPassword, setOldPassword] = useState(undefined)
    const [password, setPassword] = useState(undefined)
    const [passwordError, setPasswordError] = useState("")
    const [success, setSuccess] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        api.users.getMe().then((response) => {
            setUser(response)
            setLoading(false)
        })
    }, [])

    const handleChange = (field, value) => setUser((prev) => ({ ...prev, [field]: value }));
    const handlePassword = (field, value) => setPassword(value)

    const handleSubmit = async (event) => {
        setErrorMessage('')
        setButtonLoading(true)
        api.users.update(user.id, user, true).then((response) => {
            if (password) {
                api.users.changePassword(password).then((result) => { setSuccess(true); setButtonLoading(false); }).catch((error) => { setPasswordError(error); setButtonLoading(false); })
            } else {
                setButtonLoading(false)
                setSuccess(true)
            }
        }).catch((error) => { setErrorMessage(true); setButtonLoading(false); })
    }

    return (
        <div className="min-h-screen h-full w-full flex select-none bg-gray-50">
            <Sidebar currentScreen={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-80 h-screen my-6 w-full">
                {loading ?
                    <div className="w-16 h-16 mx-auto my-auto border-b-2 border-gray-500 rounded-full animate-spin" />
                    :
                    <>
                        <p className="text-[30px] font-extrabold">Mi perfil</p>
                        <div className="grid grid-cols-2 gap-x-6 gap-y-4 w-2/3 mt-2">
                            <TextInput error={error_message.firstName} value={user?.firstName} handleChange={handleChange} name='firstName' label='Nombre' />
                            <TextInput error={error_message.lastName} value={user?.lastName} handleChange={handleChange} name='lastName' label='Apellido' />
                            <TextInput error={error_message.email} value={user?.email} handleChange={handleChange} name='email' label='Correo electrónico' />
                        </div>
                        <p className="text-[30px] font-extrabold mt-6">Cambiar contraseña</p>
                        <div className="grid grid-cols-2 gap-x-6 gap-y-4 w-2/3 mt-2">
                            <PasswordInput error={passwordError.password} handleChange={handlePassword} name='newPassword' label='Nueva contraseña' />
                        </div>
                        <div className="flex w-2/3 gap-6 mt-6">
                            <button className="flex w-full justify-center border border-[#DBDBDB] gap-2 py-2 px-4 rounded-md shadow-sm text-sm text-[#374151] font-medium bg-white">
                                Cancelar
                            </button>
                            <button onClick={(event) => handleSubmit(event)} className="flex w-full justify-center border border-primary gap-2 py-2 px-4 rounded-md shadow-sm text-sm text-white font-medium bg-primary">
                                {buttonLoading ? <div className="w-4 h-4 mx-auto my-auto border-b-2 border-white rounded-full animate-spin" /> : 'Actualizar perfil'}
                            </button>
                        </div>
                        {success && <div className="border bg-white rounded-lg p-4 text-center w-fit mt-4">
                            <p className="text-primary">Los cambios fueron realizados exitosamente.</p>
                        </div>}
                    </>}
            </div>
        </div >
    );
}
