import { useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TabsComponent({ tabs, onTabSelect }) {
  const [selectedTab, setSelectedTab] = useState(
    tabs.find((tab) => tab.current)?.name || tabs[0].name
  );

  const handleTabSelect = (tab) => {
    setSelectedTab(tab.name);
    if (onTabSelect) {
      onTabSelect(tab);
    }
  };

  return (
    <div>
      {/* Mobile view (select) */}
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          value={selectedTab}
          onChange={(e) => {
            const selected = tabs.find((tab) => tab.name === e.target.value);
            handleTabSelect(selected);
          }}
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>

      {/* Desktop view (tabs) */}
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabSelect(tab);
                }}
                aria-current={tab.name === selectedTab ? "page" : undefined}
                className={classNames(
                  tab.name === selectedTab
                    ? "border-[#49CAA6] text-[#49CAA6]"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "w-1/2 border-b-2 px-1 py-4 text-center text-sm font-medium"
                )}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
