import React from "react";
import TextInput from "./TextInput";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CheckboxList(props) {
  const optionsContStyle = props.optionsContainerStyle
    ? props.optionsContainerStyle
    : "space-y-4 sm:flex sm:flex-col sm:items-start w-fit mx-auto sm:space-y-4 sm:gap-x-0";

  return (
    <div>
      <label
        className={
          props.labelClassName
            ? props.labelClassName
            : "text-sm font-medium text-gray-700"
        }
      >
        {props.label}
      </label>
      <fieldset className={props.label ? "mt-4" : ""}>
        <legend className="sr-only">{props.label}</legend>
        <div className={optionsContStyle}>
          {props.options.map((option) => (
            <div className="flex items-center" key={option.id}>
              <input
                onChange={(event) =>
                  props.handleChange(props.name, event.target.value)
                }
                id={option.id}
                name={props.name}
                type="checkbox"
                value={option.value}
                // Verifica si el valor está incluido en el array defaultValue
                checked={props.defaultValue.includes(option.value)}
                className="h-4 w-4 text-primary border-gray-300 focus:ring-0 focus:outline-none focus:ring-offset-0"
              />
              <label
                htmlFor={option.id}
                className={
                  option.labelClassName
                    ? option.labelClassName
                    : "ml-3 block text-sm font-medium text-gray-700"
                }
              >
                {option.label}
              </label>
              {props.defaultValue.includes(option.id) &&
                option.label.includes("(especificar)") && (
                  <TextInput
                    handleChange={props.handleCustomAnswer}
                    containerClassName="ml-3"
                    className="placeholder:text-[#757575] bg-gray-50 text-left text-primary font-medium placeholder:font-semibold placeholder:text-xs block w-32 h-fit shadow-sm border-0 border-b-2 border-primary focus-visible:border-primary focus-visible:outline-none focus-visible:ring-0 sm:text-sm"
                  />
                )}
            </div>
          ))}
        </div>
      </fieldset>
      {props.errorMessage && (
        <p className="mt-2 text-sm text-red-600" id={`${props.name}-error`}>
          {props.errorMessage}
        </p>
      )}
    </div>
  );
}
