import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useState } from 'react';
import { Doughnut } from "react-chartjs-2";
import { useNavigate } from "react-router-dom"

ChartJS.register(ArcElement, Tooltip, Legend);

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function getLength(number) {
    return number.toString().length;
}

function getDescription(dimension, value) {
    if (dimension === 'Compromiso' && value < 40)
        return 'La empresa se encuentra en el punto de partida en materia de políticas de compromiso, comunicación y difusión de políticas de cuidado. Te invitamos a conocer nuestras recomendaciones para tu organización en base a tu autodiagnóstico haciendo click en Mejorá tu puntaje (o en la sección Mejorar). También encontrá otros recursos y herramientas para que tu empresa pueda avanzar en esta agenda en pos de los derechos de niños, niñas y adolescentes en la sección Aprender.'
    else if (dimension === 'Compromiso' && value < 70)
        return 'La empresa cuenta con importantes avances en políticas de compromiso, comunicación y difusión de políticas de cuidado, pero aún existen desafíos. Te invitamos a conocer nuestras recomendaciones para tu organización en base a tu autodiagnóstico haciendo click en Mejorá tu puntaje (o en la sección Mejorar). También encontrá otros recursos y herramientas para que tu empresa pueda avanzar en esta agenda en pos de los derechos de niños, niñas y adolescentes en la sección Aprender.'
    else if (dimension === 'Compromiso')
        return 'La empresa ha avanzado mucho en relación con la comunicación, compromiso y difusión de sus políticas de cuidado. Consolidá los logros alcanzados para que tu empresa siga destacándose. Te invitamos a conocer nuestras recomendaciones para tu organización en base a tu autodiagnóstico haciendo click en Mejorá tu puntaje (o en la sección Mejorar). También encontrá otros recursos y herramientas para que tu empresa pueda avanzar en esta agenda en pos de los derechos de niños, niñas y adolescentes en la sección Aprender. Además, te invitamos a ser portavoz de las políticas de cuidado para impulsar un impacto positivo en otras empresas. ¡Contactanos!'
    else if (dimension === 'Implementación' && value < 40)
        return 'La empresa se encuentra transitando los primeros pasos en la implementación de políticas de cuidado desde un enfoque de derechos de niños y niñas y género. . Te invitamos a conocer nuestras recomendaciones para tu organización en base a tu autodiagnóstico haciendo click en Mejorá tu puntaje (o en la sección Mejorar). También encontrá otros recursos y herramientas para que tu empresa pueda avanzar en esta agenda en pos de los derechos de niños, niñas y adolescentes en la sección Aprender.'
    else if (dimension === 'Implementación' && value < 70)
        return 'La empresa ha superado ya la etapa de implementación inicial de políticas de cuidado, desarrollando acciones que favorecen la conciliación laboral y familiar de su personal. Te invitamos a conocer nuestras recomendaciones para tu organización en base a tu autodiagnóstico haciendo click en Mejorá tu puntaje (o en la sección Mejorar). También encontrá otros recursos y herramientas para que tu empresa pueda avanzar en esta agenda en pos de los derechos de niños, niñas y adolescentes en la sección Aprender.'
    else if (dimension === 'Implementación')
        return 'La empresa ha logrado sustantivos avances. Chequeá que la estrategia de tu empresa contemple los tiempos para cuidar, servicios e infraestructura de apoyo para el cuidado y el dinero para cuidar, como así también otras políticas que nutren y retroalimentan una cultura organizacional que respete y apoye las responsabilidades de cuidado de su personal. . Te invitamos a conocer nuestras recomendaciones para tu organización en base a tu autodiagnóstico haciendo click en Mejorá tu puntaje (o en la sección Mejorar). También encontrá otros recursos y herramientas para que tu empresa pueda avanzar en esta agenda en pos de los derechos de niños, niñas y adolescentes en la sección Aprender.'
    else if (dimension === 'Medición' && value < 40)
        return 'La empresa se encuentra transitando una primera etapa en relación con la medición, la evaluación y la transparencia de sus políticas de cuidado. Te invitamos a conocer nuestras recomendaciones para tu organización en base a tu autodiagnóstico haciendo click en Mejorá tu puntaje (o en la sección Mejorar). También encontrá otros recursos y herramientas para que tu empresa pueda avanzar en esta agenda en pos de los derechos de niños, niñas y adolescentes en la sección Aprender.'
    else if (dimension === 'Medición' && value < 70)
        return 'La empresa ha conseguido logros importantes en términos de seguimiento y evaluación de las políticas de cuidado impulsadas. Te invitamos a conocer nuestras recomendaciones para tu organización en base a tu autodiagnóstico haciendo click en Mejorá tu puntaje (o en la sección Mejorar). También encontrá otros recursos y herramientas para que tu empresa pueda avanzar en esta agenda en pos de los derechos de niños, niñas y adolescentes en la sección Aprender.'
    else
        return 'La empresa ha logrado avances significativos tanto en la recolección de información sobre sus políticas de cuidado como en la difusión de estos datos. Es fundamental dar continuidad a este trabajo. Te invitamos a conocer nuestras recomendaciones para tu organización en base a tu autodiagnóstico haciendo click en Mejorá tu puntaje (o en la sección Mejorar). También encontrá otros recursos y herramientas para que tu empresa pueda avanzar en esta agenda en pos de los derechos de niños, niñas y adolescentes en la sección Aprender. Además, tu empresa puede compartir y publicar sus lecciones aprendidas y datos de interés (como indicadores de impacto) ya sea en informes de responsabilidad social empresaria, en espacios de cámaras empresariales, o en redes de empresas como “Empresas que Cuidan”, para incentivar a otros. Si querés dar a conocer tus iniciativas, contactanos.'
}

export default function DimensionGauge({ title, index, percentage, description, color }) {

    const navigate = useNavigate()
    const [showMore, setShowMore] = useState(false)
    const data = {
        labels: ['Porcentaje'],
        datasets: [
            {
                data: [percentage, 100 - percentage],
                backgroundColor: [color, '#DDDDDD'],
                borderColor: [color, '#DDDDDD'],
                borderWidth: 0.1,
            }
        ],
    };

    const options = {
        radius: '100%',
        cutout: '90%',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { display: false },
            tooltip: { enabled: false },
        }

    }

    return (
        <div className="border border-[#B4B4B4] h-fit rounded-[32px] text-center bg-white p-8 flex flex-col justify-center">
            <p className="font-semibold text-[#B4B4B4] text-sm">Dimension #{index}</p>
            <div className="flex gap-2.5 mx-auto w-fit mt-4">
                <div className='w-9 h-4 rounded-[28px] my-auto border' style={{ backgroundColor: color, borderColor: color }} />
                <p className="font-semibold text-[#5C5C5C] text-[20px]">{title}</p>
            </div>
            <div className='mt-4 relative'>
                <Doughnut data={data} options={options} />
                <p className='absolute top-[35%] text-4xl font-semibold' style={{ color: color, left: getLength(percentage) > 1 ? '40%' : '45%' }}>{percentage}%</p>
            </div>
            <p className="font-semibold text-[#5C5C5C] mt-8">{percentage > 50 ? '¡Buen trabajo!' : 'Se puede mejorar'}</p>
            <p className={classNames("text-sm text-[#BABABA] leading-[20px] mt-8 height-32", showMore ? "" : "line-clamp-4")}>{getDescription(title, percentage)}</p>
            <button onClick={() => setShowMore(!showMore)} className="mt-10 mx-auto w-fit text-[#929292] font-semibold">Leer {showMore ? 'menos' : 'más'}</button>
            <button onClick={() => navigate('/improvements')} className="mt-6 mx-auto w-fit border border-[#2095DF] rounded-[18px] text-[#2095DF] px-16 py-2 font-semibold">Mejorar</button>
        </div>
    )
}