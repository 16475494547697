import { ArrowUpRightIcon, Bars4Icon, CheckCircleIcon } from "@heroicons/react/24/outline"
import { useNavigate } from "react-router-dom"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ImprovementCard({ title, idArticle, description, color }) {

    const navigate = useNavigate()

    return (
        <div onClick={() => navigate(`/improvements/${idArticle}`)} className='rounded-[10px] bg-white w-full py-4 px-6 flex gap-3 cursor-pointer relative'>
            <div className={'justify-center p-8 h-fit rounded-lg my-auto'} style={{ backgroundColor: color }}>
                <Bars4Icon className="w-6 h-6 text-white" />
            </div>
            <div className="w-full">
                <p>{title}</p>
                <p className="text-[#6B7280]">{description}</p>
            </div>
            <div className="my-auto">
                <ArrowUpRightIcon className="h-6 w-6 text-[#D1D5DB]" />
            </div>
        </div>
    )

}