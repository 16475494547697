import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import Sidebar from "../components/Sidebar";
import DimensionCard from "../components/DimensionCard";
import ImprovementCard from "../components/ImprovementCard";
import CustomProgressBar from "../components/ProgressBar";
import { Dialog, Transition } from "@headlessui/react";
import Modal from "../components/Modal";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import TabsComponent from "../components/TabsComponent";

export default function ImprovePage() {
  const [error_message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [articles, setImprovementArticles] = useState(undefined);
  const [learnArticles, setLearnArticles] = useState(undefined);
  const [answers, setAnswers] = useState(undefined);
  const [questions, setQuestions] = useState(undefined);
  const navigate = useNavigate();
  const today = new Date();

  const colors = ["#CE73F7", "#49CAA6", "#52B1D2"];

  const tabsData = [
    { name: "Según tu diagnóstico", href: "#", current: false },
    { name: "Todos los artículos", href: "#", current: false },
  ];

  const [selectedTab, setSelectedTab] = useState(
    tabsData.find((tab) => tab.current)?.name || tabsData[0].name
  );

  const handleTabChange = (tab) => {
    setSelectedTab(tab.name);
  };

  useEffect(() => {
    api.users.getMe().then((userData) => {
      if (userData.company) {
        api.improvementArticles
          .getRecommendations(userData.company)
          .then((response) => {
            setImprovementArticles(response);
            setLoading(false);
          })
          .catch((error) => setErrorMessage(error));
        api.improvementArticles
          .getMulti("dimension")
          .then((response) => {
            setLearnArticles(response);
            setLoading(false);
          })
          .catch((error) => setErrorMessage(error));
      } else {
        setLoading(false);
        setShowModal(true);
      }
    });
  }, []);

  const warningModal = (
    <>
      <div>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
          <ExclamationTriangleIcon
            className="h-6 w-6 text-yellow-800"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            Aviso
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Antes de poder acceder a esta sección debe registrar los datos de
              su empresa
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:gap-3 sm:grid-flow-row-dense">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary sm:col-start-2 sm:text-sm"
          onClick={() => {
            navigate("/company-profile");
          }}
        >
          Aceptar
        </button>
      </div>
    </>
  );

  return (
    <div className="min-h-screen h-full w-full flex select-none bg-gray-50">
      <Sidebar
        currentScreen={"Mejorar"}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <Modal
        size="sm:max-w-lg w-full"
        content={warningModal}
        open={showModal}
        setOpen={setShowModal}
      />
      <div className="md:pl-64 min-h-screen w-full flex flex-col pb-10">
        {loading ? (
          <div className="w-16 h-16 mx-auto my-auto border-b-2 border-gray-500 rounded-full animate-spin" />
        ) : (
          <>
            <CustomProgressBar />
            <div className="mt-6 px-6 flex flex-col">
              <p className="text-2xl font-bold">Mejorar</p>
              <p className="text-[#5E5E5E] text-sm mt-2">
                En base a los resultados de tu diagnóstico, ECU crea un plan con
                recomendaciones para tu empresa. Informá periódicamente tus
                mejoras para aumentar tu puntaje y comenzar el proceso de
                cambio. ¡Siempre hay algo por mejorar!
              </p>
              <div className="border border-[#D9D9D9] w-full mt-3" />
            </div>

            <TabsComponent tabs={tabsData} onTabSelect={handleTabChange} />

            {articles &&
              selectedTab === "Según tu diagnóstico" &&
              articles.map(
                (element, index) =>
                  element.subdimensions.length > 0 && (
                    <div key={index} className="mt-10 px-6 flex flex-col gap-3">
                      <p
                        className="font-semibold"
                        style={{ color: colors[index % 3] }}
                      >
                        {element.dimension.name}
                      </p>
                      <div className="grid grid-cols-2 gap-3 w-3/4">
                        {element.subdimensions.map(
                          (subdimension, articleIndex) => (
                            <ImprovementCard
                              idArticle={subdimension.article.id}
                              title={subdimension.name}
                              description={
                                subdimension.article.subdimension.description
                              }
                              color={colors[index % 3]}
                            />
                          )
                        )}
                      </div>
                    </div>
                  )
              )}
            <div className="grid grid-cols-2 gap-3 w-3/4">
              {learnArticles &&
                selectedTab === "Todos los artículos" &&
                learnArticles.map(
                  (element, index) =>
                    element.subdimension && (
                      <div key={index} className="mt-5 flex flex-col gap-3">
                        <ImprovementCard
                          idArticle={element.subdimension.id}
                          title={element.subdimension.name}
                          description={element.subdimension.description}
                          color={
                            colors[
                              element.subdimension.dimension === 2
                                ? 0
                                : element.subdimension.dimension === 3
                                ? 1
                                : 2
                            ]
                          }
                        />
                      </div>
                    )
                )}
            </div>
            {selectedTab === "Según tu diagnóstico" &&
              !articles?.find(
                (element) => element.subdimensions.length > 0
              ) && (
                <p className="text-[#BABABA] text-lg font-semibold text-center my-auto">
                  No se han encontrado artículos de subdimensiones para mejorar
                </p>
              )}
          </>
        )}
      </div>
    </div>
  );
}
