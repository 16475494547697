import { ArrowUpRightIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import api from "../api";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DimensionCard({
  title,
  idDimension,
  idCompany,
  index,
  totalQuestions,
  answeredQuestions,
  description,
  color,
  borderColor,
  textColor,
}) {
  const navigate = useNavigate();

  const getState = (total, answered) => {
    if (answered === 0) return "Sin contestar";
    else if (answered < total) return "Incompleto";
    else return "Completado";
  };

  const restartDimension = () => {
    api.rounds
      .create({ company: idCompany, dimension: idDimension })
      .then(() => {
        navigate(`/diagnosis/${idDimension}`);
      });
  };

  return (
    <div
      onClick={
        getState(totalQuestions, answeredQuestions) !== "Completado"
          ? () => navigate(`/diagnosis/${idDimension}`)
          : null
      }
      style={{
        backgroundColor: color ? color : "white",
        borderWidth: 1,
        borderColor: borderColor ? borderColor : "#B4B4B4",
        cursor:
          getState(totalQuestions, answeredQuestions) !== "Completado"
            ? "pointer"
            : "auto",
      }}
      className="rounded-[10px] max-w-96 pt-6 pb-10 px-6 relative"
    >
      <ArrowUpRightIcon className="h-6 w-6 absolute top-7 right-5" />
      <div
        className={classNames(
          getState(totalQuestions, answeredQuestions) === "Completado"
            ? "text-[#0F766E] border border-[#0F766E] bg-[#F0FDFA]"
            : "text-[#BE123C] border border-[#BE123C] bg-red-100",
          "rounded-[10px] flex w-fit py-1 px-3 align-middle font-medium"
        )}
      >
        <p>{`${answeredQuestions}/${totalQuestions}`}</p>
        <p className="ml-4">{getState(totalQuestions, answeredQuestions)}</p>
        {getState(totalQuestions, answeredQuestions) === "Completado" && (
          <CheckCircleIcon className="h-5 w-5 mt-1 ml-1 text-[#0F766E]" />
        )}
      </div>
      {index && (
        <p className="mt-10 text-[#111827] text-sm font-medium">
          Dimensión {index}
        </p>
      )}
      <p
        style={{ color: borderColor ? borderColor : "#111827" }}
        className={classNames(index ? "" : "mt-10", "text-2xl font-semibold")}
      >
        {title}
      </p>
      <p className="text-[#6B7280]">{description}</p>
      {getState(totalQuestions, answeredQuestions) === "Completado" && (
        <div className="flex justify-between gap-3 mt-4">
          <button
            onClick={() => navigate(`/diagnosis/${idDimension}`)}
            className="rounded-xl border px-3 py-2 w-full border-[#2095DF] text-[#2095DF]"
          >
            Ver respuestas
          </button>
          <div className="relative group">
            <button
              onClick={() => restartDimension()}
              className="text-[#BE123C] bg-red-100 px-3 py-2 w-full rounded-xl"
            >
              Volver a responder
            </button>
            <div className="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 px-2 py-1 bg-gray-800 text-white text-sm rounded opacity-0 group-hover:opacity-100 w-48 z-10">
              Al presionar este botón, se eliminarán todas las respuestas
              anteriores. Asegúrate de que deseas proceder, ya que esta acción
              no se puede deshacer.
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
