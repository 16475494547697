import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import api from "../api";
import Sidebar from "../components/Sidebar";
import TextInput from "../components/TextInput";
import TextArea from "../components/TextArea";

export default function ConsultationsPage() {

    const [consultation, setConsultation] = useState(undefined)
    const [error_message, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(undefined)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        api.users.getMe().then((response) => {
            if (response.company) {
                setConsultation({ name: `${response.firstName}  ${response.lastName}`, company: response.company, email: response.email })
                api.companies.getOne(response.company).then((company) => {
                    setUser({ ...response, company: company.name })
                    setLoading(false)
                })
            } else
                setLoading(false)
        })
    }, [])

    const handleChange = (field, value) => setConsultation((prev) => ({ ...prev, [field]: value }));

    const handleSubmit = async (event) => {
        setErrorMessage('')
        setButtonLoading(true)
        api.consultations.create(consultation).then((response) => {
            setButtonLoading(false)
            setSuccess(true)
        }).catch((error) => { setErrorMessage(true); setButtonLoading(false); })
    }

    return (
        <div className="min-h-screen h-full w-full flex select-none bg-gray-50">
            <Sidebar currentScreen={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-80 h-screen my-6 w-full">
                {loading ?
                    <div className="w-16 h-16 mx-auto my-auto border-b-2 border-gray-500 rounded-full animate-spin" />
                    :
                    <>
                        <p className="text-[30px] font-extrabold">Consultas</p>
                        <div className="grid grid-cols-2 gap-x-6 gap-y-4 w-2/3 mt-2">
                            <TextInput disabled={true} error={error_message?.firstName} value={user?.firstName} handleChange={handleChange} name='firstName' label='Nombre' />
                            <TextInput disabled={true} error={error_message?.lastName} value={user?.lastName} handleChange={handleChange} name='lastName' label='Apellido' />
                            <TextInput disabled={true} error={error_message?.email} value={user?.email} handleChange={handleChange} name='email' label='Correo electrónico' />
                            <TextInput disabled={true} error={error_message?.company} value={user?.company} handleChange={handleChange} name='email' label='Empresa' />
                            <TextArea containerClassName='col-span-2' error={error_message?.query} value={consultation?.query} handleChange={handleChange} name='query' label='Consulta' />
                        </div>

                        <div className="w-fit mt-6">
                            <button onClick={(event) => handleSubmit(event)} className="flex w-full justify-center border border-primary gap-2 py-2 px-4 rounded-md shadow-sm text-sm text-white font-medium bg-primary">
                                {buttonLoading ? <div className="w-4 h-4 mx-auto my-auto border-b-2 border-white rounded-full animate-spin" /> : 'Enviar consulta'}
                            </button>
                        </div>
                        {success && <div className="border bg-white rounded-lg p-4 text-center w-fit mt-4">
                            <p className="text-primary">Su consulta fue enviada exitosamente.</p>
                        </div>}
                    </>}
            </div>
        </div >
    );
}
