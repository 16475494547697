import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import Sidebar from "../components/Sidebar";
import { CheckIcon } from "../assets/Icons";
import CustomProgressBar from "../components/ProgressBar";
import TestImage from "../assets/Test.jpg";

export default function LearningArticlePage() {
  const [error_message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [article, setLearnArticle] = useState(undefined);
  const { idArticle } = useParams();
  const navigate = useNavigate();
  const today = new Date();

  useEffect(() => {
    api.learnArticles
      .getOne(idArticle)
      .then((response) => {
        setLearnArticle(response);
        setLoading(false);
      })
      .catch((error) => setErrorMessage(error));
  }, []);

  return (
    <div className="min-h-screen h-full w-full flex select-none bg-gray-50">
      <Sidebar
        currentScreen={"Aprender"}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <div className="md:pl-64 min-h-screen w-full flex flex-col pb-10">
        {loading ? (
          <div className="w-16 h-16 mx-auto my-auto border-b-2 border-gray-500 rounded-full animate-spin" />
        ) : (
          <>
            <CustomProgressBar />
            <div className="mt-6 px-6 flex flex-col">
              <p className="text-2xl font-bold">{article?.title}</p>
              <p className="text-[#BABABA] text-sm mt-2">{article?.subtitle}</p>
              <div className="border border-[#D9D9D9] w-full mt-3" />
            </div>
            <div className="flex gap-6 mt-4 px-6">
              {article?.tags &&
                article.tags.map((element, idx) => (
                  <div
                    key={idx}
                    className="border border-[#F27F50] w-fit text-[#F27F50] text-sm font-medium top-3 left-4 rounded-md py-2 px-3"
                  >
                    {element.name}
                  </div>
                ))}
            </div>
            <div className="flex flex-col relative md:max-w-[700px] px-10 md:px-0 mt-10  m-auto">
              <img
                src={article?.image ? article.image : TestImage}
                className="md:h-[500px] overflow-hidden object-scale-down object-center"
              />
              <span className="w-full text-xs text-gray-500  dark:text-gray-400">
                {article?.copyright}
              </span>
              <span className="w-full text-sm text-left text-gray-500 dark:text-gray-400 italic">
                {article?.caption}
              </span>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: article?.content }}
              className="article mt-4 px-32 flex flex-col gap-6"
            />
            <a
              href={article?.link}
              target="_BLANK"
              rel="noopener noreferrer"
              className="underline cursor-pointer px-32 mt-4"
            >
              Más información
            </a>
            <p className="mt-4 px-32 italic font-normal text-[#BABABA] hidden">
              Este material es una adaptación de la publicación realizada por
              UNICEF Argentina y el Equipo Latinoamericano de Justicia y Género
              (ELA)
            </p>
          </>
        )}
      </div>
    </div>
  );
}
