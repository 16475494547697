import React, { useState } from "react";
import ECULogo from "../assets/ECULogo.svg"

export default function TermsPage() {

    return (
        <div className="min-h-screen h-full justify-center select-none bg-gray-50">
            <img
                className="h-48 w-48 py-10 mx-auto"
                src={ECULogo}
                alt="ECU"
            />
            <div className="w-3/4 text-left my-auto mx-auto flex flex-col gap-6 pb-32">
                <h1 className="text-2xl font-semibold">Términos y Condiciones</h1>

                <h2 className="underline font-semibold text-center">Términos y Condiciones</h2>

                <p>La herramienta digital de diagnóstico y de mejora continua de UNICEF, desarrollada en el marco de la iniciativa “Empresas Que Cuidan”, que tiene como objetivo principal promover políticas de conciliación de la vida laboral y familiar (o políticas de cuidado) hacia al interior de las empresas mediante una plataforma digital.</p>

                <p>1 – Para la utilización de la herramienta de la iniciativa “Empresas Que Cuidan”, en adelante ECU, el usuario debe registrarse. El cumplimiento de los pasos necesarios a los fines del registro, implican la aceptación voluntaria y expresa de las Políticas de Privacidad de ECU.</p>

                <p>2 - Para cumplir con el proceso de registración, se solicitará al USUARIO la siguiente información: nombre personal y de la empresa a la que representa; sector al que pertenece la empresa; tamaño de la empresa, en referencia a la cantidad de trabajadores; datos de un contacto; cargo que el contacto proporcionado ocupa en la empresa, teléfono de contacto, foto de perfil y documentación de validación (carta de presentación, tarjeta de identificación empresarial, entre otras)</p>

                <p> 3 - El USUARIO autoriza en forma expresa a ECU y a UNICEF a la recopilación y almacenamiento de sus datos de registración y de la información brindada en oportunidad de la utilización de la herramienta ECU, así como a su utilización para la notificación y envio de documentación, información y/o promociones relacionadas a ECU.</p>

                <p>Asimismo, el USUARIO autoriza en forma expresa a UNICEF la utilización de la información en modo anónimo para la realización de estadísticas, informes, reportes y todo otro tipo de producción informativa y a la difusión pública de los mismos en la forma que UNICEF considere conveniente.</p>

                <p> 4 – En el proceso de utilización de la herramienta de diagnóstico de ECU, se requerirá al USUARIO cierta información relativa a las políticas de cuidado del personal; a la existencia de medidas tendientes a mejorar las ecuación familia/trabajo; a la existencia de medios de evaluación y medición del impacto sobre el personal de las políticas implementadas; a los beneficios, licencias, excedencia y/u otras formas de incentivo y protección para la maternidad, la paternidad y la lactancia; a las políticas referidas a la igualdad de oportunidades, a evitar el trato discriminatorio; entre otras. La información brindada voluntariamente por el USUARIO en oportunidad de la utilización de ECU, será almacenada por UNICEF en una base de datos y garantiza su seguridad y confidencialidad.</p>

                <p> 5 – Adicionalmente, ECU recopilará en forma automática cierta información del USUARIO, tal como sistema operativo e identificadores técnicos. Esta información también será almacenada por UNICEF y recibirá el tratamiento previsto para el resto de la información recopilada.</p>

                <p> Eventualmente, ECU puede utilizar cookies. Son archivos temporales de un tamaño muy pequeño que se generan en la computadora del usuario cuando éste navegue por el sitio. Tienen por finalidad facilitar la navegación y proporcionar a UNICEF información anónima sobre el uso del sitio que le ayudará a optimizarlo y mejorarlo. En ningún caso las cookies proporcionan información personal del usuario, ya que no almacenan información personal, solo información sobre el uso del sitio. Por cuestiones técnicas, las cookies son totalmente anónimas, y el usuario mantendrá pleno anonimato, aún frente a UNICEF.</p>

                <p> 6 - ECU no requerirá al USUARIO información “sensible” , tal como la que requiera detalles sobre el origen racial y étnico, opiniones políticas, convicciones religiosas, filosóficas o morales, afiliación sindical e información referente a la salud o a la vida sexual.</p>

                <p> 7 – Para la prestación de diversos servicios, es posible que UNICEF contrate ciertas prestaciones a cargo de terceros. Estas prestaciones podrán consistir, entre otras, en: tratamiento automatizado de datos, almacenamiento de información, o servicios de envío de comunicaciones electrónicas. Para la adecuada prestación de los servicios, es posible que los terceros a cargo de los mismos requieran acceso a las bases de datos de UNICEF. Sin embargo, UNICEF no cede a estos terceros información de orden personal de ningún tipo; el acceso por parte de los mismos a las bases de datos de UNICEF se limita estrictamente a las actividades necesarias para la prestación del servicio, siéndoles prohibida la extracción de datos o la realización de copias totales o parciales de las bases de datos de UNICEF. Todos los terceros operan bajo contratos estrictos de confidencialidad de la información, con importantes sanciones y penas establecidas por mal uso o abuso de la misma.</p>

                <p> 8 – La información recopilada será almacenada por UNICEF por el término que resulte necesario a los fines de la correcta utilización y aplicación de ECU, así como de la normativa vigente aplicable a plazos de almacenamiento. Sin perjuicio de esto, el USUARIO podrá solicitar en cualquier momento la cancelación de su acceso a ECU, a través de los medios de contacto ofrecidos en la web de la herramienta. Una vez cancelado el acceso del USUARIO, UNICEF no tendrá obligación de almacenar la información recopilada, por más tiempo que el necesario a los fines y efectos ya referidos.</p>

                <p> 9 – UNICEF se reserva el derecho de modificar las presentes Políticas de Privacidad, en cualquier momento y sin requerir autorización previa de los USUARIOS. Sin perjuicio de esto, UNICEF notificará al correo electrónico denunciado en el proceso de registración, las nuevas Políticas de Privacidad adoptadas.</p>
            </div>
        </div >
    );
}
