import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import api from "../api";
import Sidebar from "../components/Sidebar";
import DimensionCard from "../components/DimensionCard";
import ImprovementCard from "../components/ImprovementCard";
import { CheckIcon } from "../assets/Icons";
import CustomProgressBar from "../components/ProgressBar";
import parse from 'html-react-parser';

export default function ImprovementArticlePage() {

    const [error_message, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [article, setImprovementArticle] = useState(undefined)
    const { idArticle } = useParams()
    const navigate = useNavigate();
    const today = new Date()

    useEffect(() => {
        api.improvementArticles.getOne(idArticle).then((response) => {
            setImprovementArticle(response)
            setLoading(false)
        }).catch((error) => setErrorMessage(error))
    }, [])

    return (
        <div className="min-h-screen h-full w-full flex select-none bg-gray-50">
            <Sidebar currentScreen={'Mejorar'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 min-h-screen w-full flex flex-col pb-10">
                {loading ?
                    <div className="w-16 h-16 mx-auto my-auto border-b-2 border-gray-500 rounded-full animate-spin" />
                    :
                    <>
                        <CustomProgressBar />
                        <div className="mt-6 px-6 flex flex-col">
                            <p className="text-2xl font-bold">Mejorar | {article?.subdimension?.name}</p>
                            <p className="text-[#BABABA] text-sm mt-2">{article?.subdimension?.description}</p>
                            <div className="border border-[#D9D9D9] w-full mt-3" />
                        </div>
                        <div className="article mt-10 px-32 flex flex-col gap-6" >
                            {article?.content && parse(article.content)}
                        </div>
                        <p className="mt-4 px-32 italic font-normal text-[#BABABA]">Este material es una adaptación de la publicación realizada por UNICEF Argentina y el Equipo Latinoamericano de Justicia y Género (ELA)</p>
                        <button onClick={() => navigate(`/diagnosis/${article?.subdimension?.dimension}/${article?.subdimension?.id}`)} className="mt-6 mx-auto w-fit border border-primary rounded-[18px] text-primary px-16 py-2 font-semibold">ACTUALIZAR DIAGNÓSTICO</button>
                    </>
                }
            </div>
        </div >
    );
}
