function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TextArea({ name, label, placeholder, containerClassName, handleChange, error, rows, value, Idx }) {
    return (
        <div className={containerClassName}>
            <label htmlFor={name} className="text-sm font-semibold text-gray-700">
                {label}
            </label>
            <textarea
                onChange={(event) => Idx != null ? handleChange(name, event.target.value, Idx) : handleChange(name, event.target.value)}
                name={name}
                id={name}
                value={value}
                rows={rows ? rows : 4}
                className={classNames(error ? 'border border-red-500' : 'border border-[#DBDBDB]', 'rounded-md py-2 mt-1 placeholder:text-[#757575] placeholder:font-semibold placeholder:text-xs block w-full px-2 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm')}
                placeholder={placeholder}
            />
            {error && <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
                {error}
            </p>}
        </div>
    )

}