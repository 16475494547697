function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TextInput({ name, label, labelClassName, placeholder, value, containerClassName, className, handleChange, error, disabled }) {
    return (
        <div className={classNames(containerClassName ? containerClassName : 'h-auto', "mt-1")}>
            <label htmlFor={name} className={classNames(labelClassName ? labelClassName : "block text-sm font-medium leading-6 text-gray-900")}>
                {label}
            </label>
            <input
                type="text"
                onChange={(event) => handleChange(name, event.target.value)}
                name={name}
                id={name}
                disabled={disabled}
                value={value}
                className={classNames(disabled ? 'bg-[#F8F8F8]' : '', error ? 'border-2 border-red-700' : 'border border-[#DBDBDB]', className ? className : "rounded-md placeholder:text-[#757575] placeholder:font-semibold placeholder:text-xs block w-full h-fit px-3 py-2 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm")}
                placeholder={placeholder}
            />
            {error && <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
                {error}
            </p>}
        </div>
    )

}