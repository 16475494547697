export const BoardIcon = ({ color }) => (
    <svg className="mr-3" width="24" height="25" viewBox="0 0 24 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_756_1088)">
            <path d="M21.6 2.75H8.4C8.07 2.75 7.8 3.0875 7.8 3.5V5.43594C7.245 5.15469 6.6375 5 6 5V3.5C6 1.84531 7.07625 0.5 8.4 0.5H21.6C22.9238 0.5 24 1.84531 24 3.5V17C24 18.6547 22.9238 20 21.6 20H20.4H19.5H14.7H13.8H12.63C12.3188 19.1562 11.8875 18.3969 11.3663 17.75H13.8V15.875C13.8 14.4266 14.7413 13.25 15.9 13.25H18.3C19.4588 13.25 20.4 14.4266 20.4 15.875V17.75H21.6C21.93 17.75 22.2 17.4125 22.2 17V3.5C22.2 3.0875 21.93 2.75 21.6 2.75ZM18.6 17.75V15.875C18.6 15.6687 18.465 15.5 18.3 15.5H15.9C15.735 15.5 15.6 15.6687 15.6 15.875V17.75H18.6ZM7.8 11C7.8 10.4033 7.61036 9.83097 7.27279 9.40901C6.93523 8.98705 6.47739 8.75 6 8.75C5.52261 8.75 5.06477 8.98705 4.72721 9.40901C4.38964 9.83097 4.2 10.4033 4.2 11C4.2 11.5967 4.38964 12.169 4.72721 12.591C5.06477 13.0129 5.52261 13.25 6 13.25C6.47739 13.25 6.93523 13.0129 7.27279 12.591C7.61036 12.169 7.8 11.5967 7.8 11ZM2.4 11C2.4 9.80653 2.77928 8.66193 3.45442 7.81802C4.12955 6.97411 5.04522 6.5 6 6.5C6.95478 6.5 7.87045 6.97411 8.54558 7.81802C9.22072 8.66193 9.6 9.80653 9.6 11C9.6 12.1935 9.22072 13.3381 8.54558 14.182C7.87045 15.0259 6.95478 15.5 6 15.5C5.04522 15.5 4.12955 15.0259 3.45442 14.182C2.77928 13.3381 2.4 12.1935 2.4 11ZM1.90125 22.25H10.0988C9.7425 20.525 8.49 19.25 7.00125 19.25H4.99875C3.50625 19.25 2.25375 20.525 1.90125 22.25ZM0 23.2484C0 19.7984 2.23875 17 4.99875 17H6.9975C9.76125 17 12 19.7984 12 23.2484C12 23.9375 11.5538 24.5 10.9987 24.5H1.00125C0.44625 24.5 0 23.9422 0 23.2484Z" fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_756_1088">
                <rect width="24" height="24" fill={color} transform="translate(0 0.5)" />
            </clipPath>
        </defs>
    </svg>
)

export const LoudspeakerIcon = ({ color }) => (
    <svg className="mr-3" width="24" height="25" viewBox="0 0 24 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.5 2.57187C19.5 2.23437 19.0875 2.06562 18.8531 2.31406L17.7375 3.49531C15.7734 5.5625 13.2516 6.9875 10.5 7.60156V15.8984C13.2516 16.5125 15.7734 17.9328 17.7375 20.0047L18.8531 21.1812C19.0875 21.4297 19.5 21.2609 19.5 20.9234V2.57187ZM9 15.6453V7.85469C8.72812 7.8875 8.45625 7.90625 8.17969 7.925L6.79219 8H6.77344H6.75H3C2.17031 8 1.5 8.67031 1.5 9.5V14C1.5 14.8297 2.17031 15.5 3 15.5H6.75H6.76875H6.7875L8.17969 15.575C8.45156 15.5891 8.72812 15.6125 9 15.6453ZM17.7656 1.28281C18.9328 0.0499998 21 0.875 21 2.57187V20.9281C21 22.625 18.9281 23.45 17.7656 22.2172L16.65 21.0406C14.6203 18.8984 11.9156 17.5297 9.00469 17.1594V21.875C9.00469 23.3234 7.82813 24.5 6.37969 24.5H5.625C4.17656 24.5 3 23.3234 3 21.875V17C1.34531 17 0 15.6547 0 14V9.5C0 7.84531 1.34531 6.5 3 6.5H6.73125L8.1 6.425C11.3531 6.24219 14.4094 4.82656 16.65 2.46406L17.7656 1.28281ZM4.5 17V21.875C4.5 22.4984 5.00156 23 5.625 23H6.375C6.99844 23 7.5 22.4984 7.5 21.875V17.0422L6.73125 17H4.5ZM23.25 9.5C23.6625 9.5 24 9.8375 24 10.25V13.25C24 13.6625 23.6625 14 23.25 14C22.8375 14 22.5 13.6625 22.5 13.25V10.25C22.5 9.8375 22.8375 9.5 23.25 9.5Z" fill={color} />
    </svg>
)

export const StatsIcon = ({ color }) => (
    <svg className="mr-3" width="24" height="25" viewBox="0 0 24 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.6062 3.97813C24.0797 3.575 24.1312 2.8625 23.7281 2.39375C23.325 1.925 22.6125 1.86875 22.1437 2.27188L15.0094 8.38907L9.74061 3.77657C9.35624 3.44375 8.79843 3.40625 8.37655 3.6875L0.501549 8.9375C-0.014076 9.28438 -0.154701 9.98281 0.187487 10.4984C0.529674 11.0141 1.2328 11.1547 1.74842 10.8125L8.90624 6.04063L14.2594 10.7234C14.6812 11.0938 15.3094 11.0938 15.7312 10.7328L23.6062 3.98282V3.97813ZM9.37499 20.75C9.37499 20.9563 9.20624 21.125 8.99999 21.125C8.79374 21.125 8.62499 20.9563 8.62499 20.75V13.25C8.62499 13.0438 8.79374 12.875 8.99999 12.875C9.20624 12.875 9.37499 13.0438 9.37499 13.25V20.75ZM8.99999 11C7.7578 11 6.74999 12.0078 6.74999 13.25V20.75C6.74999 21.9922 7.7578 23 8.99999 23C10.2422 23 11.25 21.9922 11.25 20.75V13.25C11.25 12.0078 10.2422 11 8.99999 11ZM3.37499 20.75C3.37499 20.9563 3.20624 21.125 2.99999 21.125C2.79374 21.125 2.62499 20.9563 2.62499 20.75V17.75C2.62499 17.5438 2.79374 17.375 2.99999 17.375C3.20624 17.375 3.37499 17.5438 3.37499 17.75V20.75ZM2.99999 15.5C1.7578 15.5 0.749987 16.5078 0.749987 17.75V20.75C0.749987 21.9922 1.7578 23 2.99999 23C4.24217 23 5.24999 21.9922 5.24999 20.75V17.75C5.24999 16.5078 4.24217 15.5 2.99999 15.5ZM15.375 16.25V20.75C15.375 20.9563 15.2062 21.125 15 21.125C14.7937 21.125 14.625 20.9563 14.625 20.75V16.25C14.625 16.0438 14.7937 15.875 15 15.875C15.2062 15.875 15.375 16.0438 15.375 16.25ZM12.75 16.25V20.75C12.75 21.9922 13.7578 23 15 23C16.2422 23 17.25 21.9922 17.25 20.75V16.25C17.25 15.0078 16.2422 14 15 14C13.7578 14 12.75 15.0078 12.75 16.25ZM21.375 20.75C21.375 20.9563 21.2062 21.125 21 21.125C20.7937 21.125 20.625 20.9563 20.625 20.75V13.25C20.625 13.0438 20.7937 12.875 21 12.875C21.2062 12.875 21.375 13.0438 21.375 13.25V20.75ZM21 11C19.7578 11 18.75 12.0078 18.75 13.25V20.75C18.75 21.9922 19.7578 23 21 23C22.2422 23 23.25 21.9922 23.25 20.75V13.25C23.25 12.0078 22.2422 11 21 11Z" fill={color} />
    </svg>
)

export const QuestionMarkIcon = () => (
    <svg width="21" height="32" viewBox="0 0 21 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.528 10.112L0.584 9.584L1.592 2.24C3.448 1.472 5.192 0.975998 6.824 0.751999C8.488 0.495998 9.896 0.367998 11.048 0.367998C13.896 0.367998 16.248 0.991999 18.104 2.24C19.96 3.488 20.888 5.2 20.888 7.376C20.888 9.008 20.424 10.304 19.496 11.264C18.568 12.224 17.48 12.992 16.232 13.568C15.016 14.144 13.944 14.688 13.016 15.2C12.088 15.68 11.624 16.288 11.624 17.024C11.624 17.92 12.28 18.368 13.592 18.368V22.16C7.8 22.896 4.904 21.44 4.904 17.792C4.904 16.256 5.352 15.104 6.248 14.336C7.144 13.536 8.184 12.896 9.368 12.416C10.552 11.936 11.592 11.424 12.488 10.88C13.384 10.336 13.832 9.52 13.832 8.432C13.832 6.8 12.616 5.984 10.184 5.984C9.608 5.984 8.936 6.048 8.168 6.176C7.4 6.272 6.76 6.448 6.248 6.704L5.528 10.112ZM6.248 32V25.04H13.496V32H6.248Z" fill="#2095DF" />
    </svg>
)

export const CheckIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM17.5607 10.0607C18.1464 9.47487 18.1464 8.52513 17.5607 7.93934C16.9749 7.35355 16.0251 7.35355 15.4393 7.93934L10.5 12.8787L8.56066 10.9393C7.97487 10.3536 7.02513 10.3536 6.43934 10.9393C5.85355 11.5251 5.85355 12.4749 6.43934 13.0607L9.43934 16.0607C10.0251 16.6464 10.9749 16.6464 11.5607 16.0607L17.5607 10.0607Z" fill="#34D399" />
    </svg>
)