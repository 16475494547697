import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useState } from 'react';
import { Doughnut } from "react-chartjs-2";
import { useNavigate } from "react-router-dom"

ChartJS.register(ArcElement, Tooltip, Legend);

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function getLength(number) {
    return number.toString().length;
}

export default function RecordCard({ title, index, percentage, color }) {

    const navigate = useNavigate()
    const [showMore, setShowMore] = useState(false)
    const data = {
        labels: ['Porcentaje'],
        datasets: [
            {
                data: [percentage, 100 - percentage],
                backgroundColor: [color, '#DDDDDD'],
                borderColor: [color, '#DDDDDD'],
                borderWidth: 0.1,
            }
        ],
    };

    const options = {
        radius: '100%',
        cutout: '90%',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { display: false },
            tooltip: { enabled: false },
        }

    }

    return (
        <div className="border border-[#B4B4B4] h-fit rounded-[32px] text-center bg-white p-8 flex flex-col justify-center">
            <p className="text-lg font-semibold">Ronda #{index + 1}</p>
            <div className='mt-4 relative'>
                <Doughnut data={data} options={options} />
                <p className='absolute top-[35%] text-4xl font-semibold' style={{ color: color, left: getLength(percentage) > 1 ? '40%' : '45%' }}>{percentage}%</p>
            </div>
        </div>
    )
}